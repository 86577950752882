import React from "react";
import { Navigate } from "react-router-dom";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";

import "./index.css";
import Logo from "../../../components/Logo";
import { useGlobalContext } from "../../../GlobalContext";
import { API_URL } from "../../../app.config";
import { useNotifications } from "../../../components/Notification";
import useQueryParams from "../../../helpers/use-query-params";

export default function Login() {
  const { showNotification } = useNotifications();
  const { isLoggedIn, setIsLoggedIn } = useGlobalContext();
  const params = useQueryParams();

  const [redirect, setRedirect] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");

  return (
    <div className="login-container">
      {(redirect || isLoggedIn) && (
        <Navigate
          to={params.get("callbackUrl") ? `${params.get("callbackUrl")}` : "/"}
        />
      )}
      <form
        onSubmit={(event) => {
          event.preventDefault();
          event.stopPropagation();
          handleLogin();
        }}
      >
        <div className="login-logo-div">
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Logo iconColor="#ac8739" textColor="#000000" />
          </div>
          <div className="login-logo-text">パートナーダッシュボード</div>
        </div>
        <div className="login-field-div">
          <TextField
            className="login-field"
            variant="outlined"
            label="Eメール"
            value={email}
            onChange={(event: { target: { value: string } }) =>
              setEmail(event.target.value)
            }
          />
        </div>
        <div className="login-field-div">
          <TextField
            className="login-field"
            variant="outlined"
            label="パスワード"
            type="password"
            value={password}
            onChange={(event: { target: { value: string } }) =>
              setPassword(event.target.value)
            }
          />
        </div>
        <Button
          style={{ backgroundColor: "#c8a063", color: "#ffffff" }}
          type="submit"
          variant="contained"
        >
          ログイン
        </Button>
      </form>
      <div style={{ marginTop: "20px", fontSize: "12px" }}>
        <a
          style={{ textDecoration: "none", color: "#000" }}
          href="/forgot-password"
        >
          パスワードを忘れましたか？
        </a>
      </div>
    </div>
  );

  function handleLogin() {
    fetch(`${API_URL}/login`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ email: email, password: password }),
    }).then(async (response) => {
      if (response.status === 200) {
        const responseData = await response.json();

        localStorage.setItem(
          "user",
          JSON.stringify({
            id: responseData.userId,
            token: responseData.token,
          })
        );

        showNotification({
          message: "ログインしました!",
          severity: "success",
        });
        setIsLoggedIn(true);
        setRedirect(true);
      } else {
        showNotification({
          message: "ログインに失敗しました!",
          severity: "error",
        });
      }
    });
  }
}
