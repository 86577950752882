import FormControl from "@mui/material/FormControl";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import Divider from "@mui/material/Divider";

import TextField from "../../../components/TextField";

export default function TableToolbar(props: TableToolBarProps) {
  return (
    <div
      style={{
        marginTop: "10px",
        marginRight: "10px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Box
        component="section"
        sx={{ p: 2, display: "flex", gap: 2, alignItems: "center" }}
      >
        <Box component="section" sx={{ display: "flex", gap: 2 }}>
          <div>並び替え:</div>
          <Link
            href="#"
            onClick={() => props.columnOrder.set(null)}
            color={!props.columnOrder.value ? "primary" : "inherit"}
          >
            リクエスト受領日
          </Link>
          <Link
            href="#"
            onClick={() => props.columnOrder.set("datetime")}
            color={props.columnOrder.value ? "primary" : "inherit"}
          >
            予約日時
          </Link>
        </Box>
        <Divider orientation="vertical" flexItem />
        <FormControl>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            value={props.order.value ? props.order.value : "DESC"}
            onChange={(e) => props.order.set(e.target.value)}
          >
            <FormControlLabel
              value="DESC"
              control={<Radio size="small" />}
              label="降順"
            />
            <FormControlLabel
              value="ASC"
              control={<Radio size="small" />}
              label="昇順"
            />
          </RadioGroup>
        </FormControl>
      </Box>
      <FormControl>
        <TextField
          size="small"
          label="検索"
          value={props.search.value ? props.search.value : ""}
          onChange={(e) => props.search.set(e.target.value)}
        />
      </FormControl>
    </div>
  );
}

interface TableToolBarProps {
  search: {
    value: string | null;
    set: (value: string | null) => void;
  };
  columnOrder: {
    value: string | null;
    set: (value: string | null) => void;
  };
  order: {
    value: string | null;
    set: (value: string | null) => void;
  };
}
