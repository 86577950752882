import { Grid } from "@mui/material";
import BookingsChart from "./components/charts/BookingsChart";
import AcceptanceRateSpeedometer from "./components/charts/AcceptanceRateSpeedometer";
import ScopeSelector, { allVenuesLabel } from "./components/ScopeSelector";
import DashboardItem from "./components/DashboardItem";
import WarningIcon from "@mui/icons-material/Warning";
import { gql, useQuery } from "@apollo/client";
import { useCallback, useMemo } from "react";
import Tooltip from "@mui/material/Tooltip";
import { useSearchParams } from "react-router-dom";
import { formatDate, getStartOfWeek } from "../../../helpers/date-functions";

const minAcceptanceRate = 95;

export default function Dashboard() {
  const [searchParams, setSearchParams] = useSearchParams();

  // scope id refers to either the plan id or the venue id
  const scopeId = useMemo(() => {
    return searchParams.get("id");
  }, [searchParams]);

  const setScopeId = useCallback(
    (scopeId: string | null) => {
      scopeId ? setSearchParams({ id: scopeId }) : setSearchParams();
    },
    [setSearchParams]
  );

  const { data, loading } = useQuery(GET_BOOKINGS_AND_REJECTIONS_QUERY, {
    variables: {
      input: {
        // get bookings of last 12 weeks (this week + 11 weeks in the past)
        from: formatDate(
          getStartOfWeek(new Date(Date.now() - 7 * 11 * 24 * 60 * 60 * 1000))
        ),
      },
    },
  });

  const plansPerVenue: Map<string, VenueWithPlans> = useMemo(() => {
    if (data === undefined) return new Map();

    return (data.plans as PlanResponse[]).reduce((acc, plan) => {
      const venue = plan.activities[0].venue;
      if (!venue) return acc;
      const venueEntry = acc.get(venue.id) ?? {
        venueName: venue.name,
        plans: [],
      };
      acc.set(venue.id, {
        ...venueEntry,
        plans: [...venueEntry.plans, { planId: plan.id, planName: plan.name }],
      });
      return acc;
    }, new Map<string, VenueWithPlans>());
  }, [data]);

  const plan = useMemo(() => {
    if (data === undefined) return undefined;
    const p = (data.plans as PlanResponse[]).find((plan) => {
      return plan.id === scopeId;
    });
    return p ? { id: scopeId!, name: p.name } : undefined;
  }, [data, scopeId]);

  const venue = useMemo(() => {
    if (data === undefined) return undefined;
    const venue = plansPerVenue.get(scopeId ?? "");
    return venue ? { id: scopeId!, name: venue.venueName } : undefined;
  }, [data, scopeId, plansPerVenue]);

  const bookings = useMemo(() => {
    return accumulateBookingStats(data, plan?.id, venue?.id, "bookings");
  }, [data, plan, venue]);

  const rejections = useMemo(() => {
    return accumulateBookingStats(data, plan?.id, venue?.id, "rejections");
  }, [data, plan, venue]);

  const acceptanceRate = useMemo(() => {
    if (bookings.length === 0) return undefined;
    return 100 - Math.floor((100 * rejections.length) / bookings.length);
  }, [bookings, rejections]);

  const selectorLabel = useMemo(() => {
    if (plan !== undefined) return plan.name;
    else if (venue !== undefined) return venue.name;
    else
      return plansPerVenue.size > 1
        ? allVenuesLabel
        : [...plansPerVenue.values()][0]?.venueName;
  }, [plan, venue, plansPerVenue]);

  return (
    <div className="Dashboard">
      <ScopeSelector
        loading={loading}
        label={selectorLabel}
        plansPerVenue={plansPerVenue}
        scopeId={scopeId}
        setScopeId={setScopeId}
      />

      <Grid container spacing={2} marginBottom="1rem">
        <DashboardItem loading={loading} header="予約数(直近3ヶ月)">
          <BookingsChart bookings={bookings} rejections={rejections} />
        </DashboardItem>
        <DashboardItem
          loading={loading}
          header="予約承認率(直近3ヶ月)"
          HeaderIcon={
            acceptanceRate && acceptanceRate < minAcceptanceRate ? (
              <Tooltip title={`${minAcceptanceRate}%未満`}>
                <WarningIcon />
              </Tooltip>
            ) : undefined
          }
        >
          <AcceptanceRateSpeedometer
            acceptanceRate={acceptanceRate}
            minAcceptanceRate={minAcceptanceRate}
          />
        </DashboardItem>
      </Grid>
    </div>
  );
}

const GET_BOOKINGS_AND_REJECTIONS_QUERY = gql`
  query GetPlans($input: BookingsInput!) {
    plans(filter: { fetchAllIfPermitted: true }) {
      id
      name
      activities {
        venue {
          id
          name
        }
      }
      bookings(input: $input) {
        bookings
        rejections
      }
    }
  }
`;

interface PlanResponse {
  id: string;
  name: string;
  activities: {
    venue: {
      id: string;
      name: string;
    };
  }[];
  bookings: {
    bookings: string[];
    rejections: string[];
  };
}

export interface VenueWithPlans {
  venueName: string;
  plans: {
    planId: string;
    planName: string;
  }[];
}

const accumulateBookingStats = (
  data: { plans: PlanResponse[] } | undefined,
  planId: string | undefined,
  venueId: string | undefined,
  attribute: "bookings" | "rejections"
) => {
  // get a flat list of bookings/rejections based on the scope

  if (data === undefined) return [];

  let plans: PlanResponse[];

  if (planId !== undefined)
    plans = [data.plans.find((plan) => plan.id === planId) as PlanResponse];
  else if (venueId !== undefined) {
    plans = data.plans.filter(
      (plan) => plan.activities[0]?.venue?.id === venueId
    );
  } else plans = data.plans;

  return plans
    .flatMap((plan) => plan.bookings[attribute].map((x) => new Date(x)))
    .sort();
};
