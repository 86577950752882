import { useQuery, gql } from "@apollo/client";
import { DataGrid } from "@mui/x-data-grid";
import FilterAltIcon from "@mui/icons-material/FilterAlt";

import { useState } from "react";
import renders from "./column-renders";
import Modal from "./Modal";
import TableToolbar from "./TableToolbar";
import ColumnMenu from "./ColumnMenu";
import { Booking } from "../../../types";

export default function Bookings() {
  const [modal, setModal] = useState<null | string>(null);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });
  const [search, setSearch] = useState<string | null>(null);
  const [columnOrder, setColumnOrder] = useState<string | null>(null);
  const [order, setOrder] = useState<string | null>("DESC");
  const [reservationStatus, setReservationStatus] = useState<string | null>(
    null
  );
  const [fromCreated, setFromCreated] = useState<string | null>(null);
  const [untilCreated, setUntilCreated] = useState<string | null>(null);
  const [fromDatetime, setFromDatetime] = useState<string | null>(null);
  const [untilDatetime, setUntilDatetime] = useState<string | null>(null);
  const [venueId, setVenueId] = useState<string | null>(null);
  const [planId, setPlanId] = useState<string | null>(null);

  const { loading, error, data } = useQuery(GET_BOOKINGS_LIST_DATA, {
    variables: {
      offset: paginationModel.page * paginationModel.pageSize,
      limit: paginationModel.pageSize,
      search: search,
      status: reservationStatus ? [reservationStatus] : null,
      venueId: venueId,
      planId: planId,
      fromCreated: fromCreated,
      untilCreated: untilCreated,
      fromDatetime: fromDatetime,
      untilDatetime: untilDatetime,
      columnOrder: columnOrder,
      order: order,
    },
  });
  if (error) return <>Error! {error.message}</>;

  const columns = [
    getColumn("actions", "詳細", false, 100),
    getColumn("status", "予約ステータス", true, 130),
    getColumn("createdAt", "リクエスト受領日時", true, 160),
    getColumn("datetime", "予約日時", true, 90),
    getColumn("venue", "店舗名", true, 210),
    getColumn("plan", "プラン名", true, 250),
    getColumn("course", "コース名", true, 250),
    getColumn("seat", "席タイプ", false, 170),
    getColumn("customer", "ご予約名", false, 150),
    getColumn("items", "人数", true, 160),
    getColumn("payment", "予約金額/支払い手段", false, 170),
    getColumn("specialRequests", "ご要望", false, 80),
  ];

  const rows = data ? data.reservations.records : [];
  const totalRows = data ? data.reservations.totalRecords : 0;
  const venues = data ? data.venues : [];
  const plans = data ? data.plans : [];

  return (
    <>
      <DataGrid
        style={{ backgroundColor: "#fff" }}
        loading={loading}
        components={{
          Toolbar: TableToolbar,
          ColumnMenu: renderColumnMenu,
          ColumnMenuIcon: () => <FilterAltIcon fontSize="small" />,
        }}
        componentsProps={{
          toolbar: {
            search: { value: search, set: setSearch },
            columnOrder: { value: columnOrder, set: setColumnOrder },
            order: { value: order, set: setOrder },
          },
        }}
        columns={columns}
        rows={rows}
        autoHeight={true}
        disableRowSelectionOnClick={true}
        rowCount={totalRows}
        paginationMode="server"
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        pageSizeOptions={[paginationModel.pageSize]}
      />
      <Modal value={modal} setValue={setModal} />
    </>
  );

  function getColumn(field: any, name: string, menu: boolean, width?: number) {
    let column = {
      field: field,
      headerName: name,
      sortable: false,
      disableColumnMenu: menu ? false : true,
      flex: width ? undefined : 1,
      width: width ? width : undefined,
      renderCell: renders[field],
    };

    if (field === "specialRequests")
      column["renderCell"] = (params: RenderParams) =>
        renders[field](params, setModal);

    return column;
  }

  function renderColumnMenu(args: {
    hideMenu: (event: any) => void;
    colDef: { field: string };
  }) {
    return (
      <ColumnMenu
        closeMenu={args.hideMenu}
        field={args.colDef.field}
        reservationStatus={{
          value: reservationStatus,
          set: setReservationStatus,
        }}
        created={{
          from: { value: fromCreated, set: setFromCreated },
          until: { value: untilCreated, set: setUntilCreated },
        }}
        datetime={{
          from: { value: fromDatetime, set: setFromDatetime },
          until: { value: untilDatetime, set: setUntilDatetime },
        }}
        venueId={{ value: venueId, set: setVenueId }}
        planId={{ value: planId, set: setPlanId }}
        venues={venues}
        plans={plans}
      />
    );
  }
}

const GET_BOOKINGS_LIST_DATA = gql`
  query GetBookingsListData(
    $offset: Int!
    $limit: Int!
    $search: String
    $status: [ReservationStatus]
    $venueId: ID
    $planId: ID
    $fromCreated: String
    $untilCreated: String
    $fromDatetime: String
    $untilDatetime: String
    $columnOrder: String
    $order: String
  ) {
    reservations(
      input: {
        offset: $offset
        limit: $limit
        search: $search
        status: $status
        venueId: $venueId
        planId: $planId
        createdAt: { from: $fromCreated, until: $untilCreated }
        datetime: { from: $fromDatetime, until: $untilDatetime }
        columnOrder: $columnOrder
        order: $order
      }
    ) {
      totalRecords
      records {
        id
        status
        datetime
        specialRequests
        activity {
          id
          name
          venue {
            id
            name
          }
          plan {
            id
            name
          }
          json {
            json {
              name
            }
          }
        }
        seatOption {
          id
          title
        }
        booking {
          id
          noShow
          status
          familyName
          givenName
          familyNameFurigana
          givenNameFurigana
          paymentAmount
          paymentCurrency
          createdAt
          payment {
            method
          }
        }
        items {
          quantity
          priceType {
            name
          }
        }
      }
    }
    venues {
      id
      name
    }
    plans {
      id
      name
    }
  }
`;

export interface RenderParams {
  row: Reservation;
}

export interface Reservation {
  id: string;
  status: "NOT_YET_RESERVED" | "RESERVED" | "REJECTED" | "CANCELLED";
  datetime: string;
  specialRequests: string;
  activity: Activity;
  seatOption: SeatOption;
  booking: Booking;
  items: ReservationItem[];
}

interface Activity {
  id: string;
  name: string;
  venue: Venue;
  plan: Plan;
  json: {
    json: {
      name: string;
    };
  };
}

interface Venue {
  id: string;
  name: string;
}

interface Plan {
  id: string;
  name: string;
}

interface SeatOption {
  id: string;
  title: string;
}

interface ReservationItem {
  quantity: number;
  priceType: PriceType;
}

interface PriceType {
  name: string;
}
