import { gql, useQuery } from "@apollo/client";
import { useMemo } from "react";
import { useGlobalContext } from "../../GlobalContext";
import { formatDate, getStartOfWeek } from "../../helpers/date-functions";
import InfoBar from "../InfoBar";
import AcceptanceRateWarningBar from "../InfoBar/presets/AcceptanceRateWarningBar";

export default function AnnouncementsIfLoggedIn() {
  const { isLoggedIn } = useGlobalContext();
  if (!isLoggedIn) return <></>;
  return Announcements();
}

const Announcements = () => {
  const { data: announcementData, loading: announcementLoading } = useQuery(
    GET_ANNOUNCEMENTS,
    {
      variables: { offset: 0, limit: 0 },
    }
  );

  const { data: bookingData } = useQuery(GET_BOOKING_STATISTICS, {
    variables: {
      input: {
        // get bookings of last 12 weeks (this week + 11 weeks in the past)
        from: formatDate(
          getStartOfWeek(new Date(Date.now() - 7 * 11 * 24 * 60 * 60 * 1000))
        ),
      },
    },
  });

  const bookingStatistics = useMemo(() => {
    if (bookingData === undefined) return undefined;
    return bookingData.bookingStatistics;
  }, [bookingData]);

  return (
    <>
      {!announcementLoading && announcementData && (
        <ul style={{ padding: 0, marginTop: 10 }}>
          {bookingStatistics &&
            (bookingStatistics.acceptanceRate ?? 100) < 95 && (
              <AcceptanceRateWarningBar {...bookingStatistics} />
            )}

          {announcementData.announcements.map((announcement: any) => {
            return (
              <InfoBar
                key={announcement.id}
                content={announcement.content}
                synopsis={announcement.synopsis}
                variant="announcement"
              />
            );
          })}
        </ul>
      )}
    </>
  );
};

const GET_ANNOUNCEMENTS = gql`
  query GetAnnouncementsQuery {
    announcements {
      id
      content
      synopsis
    }
  }
`;

const GET_BOOKING_STATISTICS = gql`
  query GetBookingStatisticsQuery($input: BookingStatisticsInput!) {
    bookingStatistics(input: $input) {
      numBookings
      numRejections
      acceptanceRate
    }
  }
`;
