import { useEffect, useState } from "react";
import {
  useMutation,
  gql,
  ApolloCache,
  DefaultContext,
  MutationFunctionOptions,
  OperationVariables,
} from "@apollo/client";
import parse from "date-fns/parse";
import {
  Button,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
} from "@mui/material";
import StopCircleIcon from "@mui/icons-material/StopCircle";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { Reservation } from "..";
import ReservationDetails from "./ReservationDetails";
import TextField from "../../../../components/TextField";

import { useNotifications } from "../../../../components/Notification";

interface ConfirmReservationProps {
  reservation: Reservation;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const cancelStyle: React.CSSProperties = {
  fontWeight: "bold",
};

export default function CancelReservation({
  reservation,
  setLoading,
}: ConfirmReservationProps) {
  const [dialogOpen, setDialogOpen] = useState(false);

  const [cancelReservation, { loading }] = useMutation(CANCEL_RESERVATION);

  useEffect(() => setLoading(loading), [loading, setLoading]);

  const datetimeDate = parse(
    reservation.datetime,
    "yyyy-MM-dd HH:mm:ss",
    new Date()
  );
  if (datetimeDate < new Date()) return <></>;

  return (
    <>
      <Tooltip title="キャンセルする">
        <IconButton size="small" onClick={() => setDialogOpen(true)}>
          <StopCircleIcon fontSize="inherit" />
        </IconButton>
      </Tooltip>
      <Dialog open={dialogOpen}>
        <CancelForm
          reservation={reservation}
          setDialogOpen={setDialogOpen}
          cancelReservation={cancelReservation}
          setLoading={setLoading}
        />
      </Dialog>
    </>
  );
}

interface CancelFormProps {
  reservation: Reservation;
  setDialogOpen: (v: boolean) => void;
  setLoading: (v: boolean) => void;
  cancelReservation: (
    options?:
      | MutationFunctionOptions<
          any,
          OperationVariables,
          DefaultContext,
          ApolloCache<any>
        >
      | undefined
  ) => Promise<any>;
}

function CalculateCancellationFee(
  paymentAmount: number,
  cancellationRate: number
) {
  return Math.floor((paymentAmount * cancellationRate) / 100);
}

function CancelForm(props: CancelFormProps) {
  const { showNotification } = useNotifications();
  const [cancelledBy, setCancelledBy] = useState("venue");
  const [cancellationReason, setCancellationReason] = useState("");
  const [cancellationRate, setCancellationRate] = useState(0);
  const [cancellationFee, setCancellationFee] = useState(0);

  return (
    <>
      <DialogContent>
        この予約を<span style={cancelStyle}>キャンセル</span>
        してもよろしいですか？
        <ReservationDetails
          style={{ margin: "20px 0px" }}
          reservation={props.reservation}
        />
        <span style={cancelStyle}>「はい」</span>
        をご選択頂くと、ご予約者の登録メールアドレスへキャンセルメールが送信されます。
        <Grid style={{ marginTop: "10px" }} container spacing={2}>
          <Grid item xs={6}>
            <FormControl style={{ width: "100%" }}>
              <InputLabel>キャンセル主体</InputLabel>
              <Select
                label="キャンセル主体"
                value={cancelledBy}
                onChange={(e) => setCancelledBy(e.target.value)}
              >
                <MenuItem value="venue">店舗側</MenuItem>
                <MenuItem value="customer">お客様側</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="キャンセル理由"
              multiline={true}
              value={cancellationReason}
              onChange={(e) => setCancellationReason(e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="キャンセル料率"
              multiline={true}
              endadornment={<>%</>}
              value={cancellationRate}
              onChange={(e) => {
                let input = e.target.value || 0;
                const nextCancellationRate = Number(input);
                if (isNaN(nextCancellationRate)) return;
                if (nextCancellationRate < 0 || nextCancellationRate > 100)
                  return;
                setCancellationRate(nextCancellationRate);
                setCancellationFee(
                  CalculateCancellationFee(
                    props.reservation.booking.paymentAmount,
                    nextCancellationRate
                  )
                );
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="キャンセル料"
              disabled={true}
              startadornment={<>¥</>}
              readOnly={true}
              value={cancellationFee}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          style={{ color: "#000" }}
          onClick={() => props.setDialogOpen(false)}
        >
          いいえ
        </Button>
        <Button
          style={{ color: "red" }}
          onClick={() => handleCancelReservation()}
        >
          はい
        </Button>
      </DialogActions>
    </>
  );

  function handleCancelReservation() {
    if (!cancellationReason) {
      showNotification({
        message: "キャンセル理由を記入して下さい！",
        severity: "error",
      });
      return;
    }

    props
      .cancelReservation({
        variables: {
          reservationId: props.reservation.id,
          cancelledBy: cancelledBy,
          cancellationReason: cancellationReason,
          cancellationFee: cancellationFee,
        },
        refetchQueries: ["GetBookingsListData"],
      })
      .then((data: any) => {
        if (data?.data?.cancelReservation?.success === false) {
          props.setLoading(false);
          return showNotification({
            message:
              data?.data?.cancelReservation?.message ??
              "Reservation cancel failed!",
            severity: "error",
          });
        }
        showNotification({
          message: "キャンセルが完了しました！",
          severity: "success",
        });
      })
      .catch(() =>
        showNotification({
          message: "キャンセルに失敗しました！",
          severity: "error",
        })
      );

    props.setDialogOpen(false);
  }
}

const CANCEL_RESERVATION = gql`
  mutation CancelReservation(
    $reservationId: ID!
    $cancelledBy: String!
    $cancellationReason: String!
    $cancellationFee: Int!
  ) {
    cancelReservation(
      input: {
        reservationId: $reservationId
        cancelledBy: $cancelledBy
        cancellationReason: $cancellationReason
        cancellationFee: $cancellationFee
      }
    ) {
      success
    }
  }
`;
