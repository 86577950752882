import { Dispatch, SetStateAction, useState } from "react";
import Table from "./Table";
import Toolbar from "./Toolbar";
import { CircularProgress } from "@mui/material";
import useSchedules from "./useSchedules";

interface VenueAvailabilityProps {
  venue: string;
  setHasUnsavedChange: Dispatch<SetStateAction<boolean>>;
}

export default function VenueAvailability(props: VenueAvailabilityProps) {
  const [month, setMonth] = useState(new Date());

  const {
    plans,
    numBookings,
    schedules,
    setSchedules,
    loading,
    loadError,
    save,
    saving,
    canSave,
    addTimeSlot,
    toggleDate,
    toggleTimeSlot,
    toggleAllTimeSlots,
    toggleHoliday,
    toggleDateForAll,
  } = useSchedules({
    month,
    venueId: props.venue,
  });

  if (loadError) return <>Error</>;

  return (
    <>
      <Toolbar
        month={month}
        setMonth={setMonth}
        saving={saving}
        save={save}
        plans={plans}
        canSave={canSave}
      />

      {loading ? (
        <CircularProgress />
      ) : (
        <Table
          plans={plans}
          month={month}
          saving={saving}
          toggleDate={toggleDate}
          toggleTimeSlot={toggleTimeSlot}
          addTimeSlot={addTimeSlot}
          toggleDateForAll={toggleDateForAll}
          schedules={schedules}
          setSchedules={setSchedules}
          toggleAllTimeSlots={toggleAllTimeSlots}
          toggleHoliday={toggleHoliday}
          numBookings={numBookings}
        />
      )}
    </>
  );
}
