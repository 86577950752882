import { useMemo } from "react";
import Chart from "react-apexcharts";

interface AcceptanceRateSpeedometerProps {
  acceptanceRate: number | undefined;
  minAcceptanceRate: number;
}
export default function AcceptanceRateSpeedometer({
  acceptanceRate,
  minAcceptanceRate,
}: AcceptanceRateSpeedometerProps) {
  const state = useMemo(
    () => ({
      options: {
        states: {
          active: {
            filter: {
              type: "none",
            },
          },
        },
        labels: ["予約承認率"],
        stroke: {
          lineCap: "round" as "round",
        },

        chart: {
          // this id is needed, or the label formatter will not update
          id: `basic-radial-bar-${acceptanceRate}`,
          offsetY: -20,
        },

        plotOptions: {
          stroke: {
            lineCap: "round",
          },

          radialBar: {
            hollow: {
              size: "55%",
            },
            startAngle: -135,
            endAngle: 135,
            dataLabels: {
              show: true,
              name: {
                offsetY: 28,
                fontSize: "1rem",
              },
              value: {
                offsetY: -10,
                fontSize: "4rem",
                formatter: () => {
                  return acceptanceRate === undefined
                    ? ""
                    : `${acceptanceRate}%`;
                },
              },
            },
          },
        },

        colors: [
          () => {
            // no acceptance rate > grey
            if (acceptanceRate === undefined) return "#f2f2f2";
            // bad acceptance rate > red
            if (acceptanceRate < minAcceptanceRate) return "#eb4034";
            // good acceptance rate > green
            return "#1db557";
          },
        ],
      },
      series: [Math.pow((acceptanceRate ?? 0) * 0.1, 2)],
    }),
    [acceptanceRate, minAcceptanceRate]
  );

  return (
    <Chart options={state.options} series={state.series} type="radialBar" />
  );
}
