import { useQuery, gql } from "@apollo/client";
import { CircularProgress, Stack } from "@mui/material";
import StoreIcon from "@mui/icons-material/Store";
import Select from "../../../components/Select";
import styles from "./styles";

export default function VenueSelector(props: {
  venue: string | null;
  setVenue: (v: string | null) => void;
}) {
  const { loading, error, data } = useQuery<queryResponse>(GET_VENUES, {
    onCompleted: (data) => {
      if (data.venues.length === 0) return;
      if (!props.venue) props.setVenue(data.venues[0].id);
    },
  });
  if (loading) return <CircularProgress />;
  if (error) return <>Error</>;

  const venues = data ? data.venues : [];

  return (
    <Stack
      sx={styles.venueSelector}
      direction="row"
      spacing={1}
      alignItems="center"
    >
      <StoreIcon />
      <Select
        size="small"
        label="店舗"
        options={venues.map((v) => ({ value: v.id, name: v.name }))}
        value={props.venue ? props.venue : venues[0].id}
        onChange={props.setVenue}
      />
    </Stack>
  );
}

const GET_VENUES = gql`
  query GetVenues {
    venues {
      id
      name
    }
  }
`;

interface queryResponse {
  venues: { id: string; name: string }[];
}
